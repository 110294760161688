import { Fail } from '@commonstock/client/src/constants'
import { OrderStatus, OrderType } from '../api/user'
import { CommentSummary } from '../api/comment'
import { Likes } from '../api/like'
import { HydratedUserProfile } from '../api/profile'
import { OptionContract, PositionChangeType, TradePositionType } from '../api/feed'

export type Asset = {
  id: number
  currency: string
  type: AssetType
  kind: AssetKind
  name: string
  short_name: string
  symbol: string
}

export type AssetV1 = {
  id: number
  currency: string
  type: AssetType
  name: string
  symbol: string
}

export enum AssetType {
  cash = 'cash',
  currency = 'currency',
  crypto = 'crypto',
  equity = 'equity'
}

export enum AssetKind {
  crypto = 'crypto',
  currency = 'currency',
  equity = 'equity',
  stock = 'stock',
  exchangeTraded = 'exchange-traded',
  adr = 'adr'
}

export type WatchlistAsset = {
  id: number
  asset_symbol: string
  asset_type: AssetKind
  user_id: number
  watched_at: number
}

export type User = {
  // @TODO remove id to force migration
  id: number
  uuid: string
  email: string
  first_name: string
  last_name: string
  profile_picture: string
  is_onboarded: boolean
  ever_asked_to_join_group: boolean
  group_memberships: number
  sb_user_id: string
  bio: string
  website: string
  meta: {
    web_onboarding: string
    no_broker_modal_shown: 'yes' | 'no'
    first_web_login: string
    welcome_modal_shown?: 'yes' | 'no'
  }
}

export type ChartDataValue = { a: number; c: number; t: number; v: number }
export type ChartData = {
  period_change_value: number
  period_title: Periods
  reference_value: number
  period_current_value: number
  period_return: number
  values: ChartDataValue[]
  max_points: number
  live: boolean
  benchmark?: Array<{ c: number }>
}

export type ChatTradeType = {
  user: HydratedUserProfile
  asset: {
    id: number
    symbol: string
    name: string
    short_name: string
    type: AssetType
    change_percentage: number
    current_price: number
    currency: string
    change_value: number
  }
  trade: {
    uuid: string
    asset_symbol: string
    asset_type: string
    price: number
    date: string
    date_timestamp: number
    change_percentage: number
    option_contract: OptionContract | null
    percent_return: number | null
    position_percent_change: number | null
    position_change_type: PositionChangeType | null
    security: string
    title: string
    trade_type: TradePositionType | null
    transaction_type: string
  }
}

export function isTradeType(item: ChatTradeType | TradeType): item is TradeType {
  return Object.prototype.hasOwnProperty.call(item, 'order')
}

/**
 * @deprecated
 */
export type TradeType = {
  copied_user: HydratedUserProfile | null
  // memo: Memo
  user: HydratedUserProfile
  broker: {
    identifier: string
    name: string
  }
  likes: Likes
  asset: {
    name: string
    short_name: string
    symbol: string
    type: AssetType
  }
  order: {
    identifier: string
    account_identifier: string
    transaction_type: OrderType
    asset_symbol: string
    asset_type: AssetType
    quantity: number
    price: number
    date: string
    date_timestamp: number
    change_percentage: number
    times_copied: number
    copied_by_me: boolean
    status: OrderStatus
    percent_return: number | null
    position_percent_change: number | null
    position_change_type: PositionChangeType | null
  }
  comments: CommentSummary
}

export enum Periods {
  '1D' = '1D',
  '1W' = '1W',
  '1M' = '1M',
  '3M' = '3M',
  '1Y' = '1Y',
  'YTD' = 'YTD',
  'ALL' = 'ALL'
}

export const PeriodValues = Object.values(Periods)

export enum PostStage {
  Latent = 'latent',
  Pending = 'pending',
  Fail = 'failed',
  Success = 'success'
}

export type PostState =
  | { stage: PostStage.Latent; fail?: never }
  | { stage: PostStage.Pending; fail?: never }
  | { stage: PostStage.Success; fail?: never }
  | { stage: PostStage.Fail; fail: Fail }

export type Picture = {
  file: string
  item: string
  type: string
}

export enum TagType {
  CATEGORY = 'CATEGORY',
  STRATEGY = 'STRATEGY'
}

export enum TagItemType {
  TRADE = 'TRADE',
  MEMO_PLUS = 'MEMO_PLUS',
  COMMENT = 'COMMENT',
  PROFILE = 'PROFILE'
}

export enum UserRole {
  admin = 'admin',
  moderator = 'moderator',
  employee = 'employee'
}

export enum ArchivedContentType {
  MEMO_PLUS = 'MEMO_PLUS',
  COMMENT = 'COMMENT'
}
